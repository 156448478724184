<template>
  <el-drawer class="h5-drawer" :title="title" append-to-body :visible.sync="show" size="75%">
    <el-tree ref="tree" :data="options" :props="treeProps" highlight-current node-key="id" :expand-on-click-node="false" @current-change="handleChange" />
  </el-drawer>
</template>

<script>
export default {
  props: {
    options: Array,
    value: Number | String,
    label: String,
    visible: Boolean,
    title: {
      type: String,
      default: "请选择",
    },
  },
  watch: {
    visible: function (val) {
      this.show = val;
    },
    show: function (val) {
      this.$emit("update:visible", val);
    },
    value: function (n, o) {
      if (n != o) {
        this.setSelect(n);
      }
    },
    options: function () {
      if (this.value) this.setSelect(this.value);
    },
  },
  data() {
    return {
      selected: null,
      show: false,
      treeProps: {
        label: "name",
      },
    };
  },
  methods: {
    handleChange(e) {
      this.$emit("input", e.id);
      this.$emit("update:label", e.name);
      this.show = false;
    },
    setSelect(v) {
      if (this.$refs.tree) {
        this.$refs.tree.setCurrentKey(v);
        this.handleChange(this.$refs.tree.getCurrentNode());
      }
    },
  },
};
</script>